export const amTasks = [
  {name: "Brush Teeth", value: false},
  {name: "Foam Roller", value: false},
  {name: "Chest Stretch", value: false},
  {name: "Back Exercise", value: false},
  {name: "Push Ups", value: false},
  {name: "Running Stretches", value: false},
];

export const pmTasks = [
  {name: "Chest Stretch", value: false},
  {name: "Back Exercise", value: false},
  {name: "Foam Roller", value: false},
  {name: "Neck/Back", value: false},
  {name: "Brush/Floss", value: false},
]
